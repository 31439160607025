.base-container-portrait {
    position: fixed;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    min-width: 100vw;
    overflow: hidden;
    background-color: #b52537;
}

.screen-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 85vw;
    height: 45vh;
    margin-top: 3vh;
    border-radius: 8px;
    background-color: black;
}

.screen-container .screen {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75%;
    width: 85%;
    margin-top: 2vh;
    aspect-ratio: 1/1;
    border: 1px solid #4a4e44;
    font-family: "Press Start 2P", system-ui;
    color: black;
    text-align: center;
    background-color: #5d6154;
    box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.5);
}

.screen p {
    padding: 0.6rem 0 0.8rem 0;
    font-size: 0.7rem;
}

.screen> :last-child {
    width: 100%;
    font-size: 0.7rem;
    flex-wrap: wrap;
}

.screen .menu-options {
    display: flex;
    flex-direction: column;
    padding-top: 2vh;
    gap: 10px;
    list-style-type: none;
}

.screen-container .logo {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 10%;
    padding-top: 0.9rem;
    margin-bottom: 0.5vh;
    gap: 3vw;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
    -webkit-touch-callout: none;
}

.screen-container .logo .main {
    display: flex;
    font-family: "Cabin", sans-serif;
    font-weight: 800;
    font-style: italic;
    font-size: 2rem;
    color: #939393;
}

.screen-container .logo .color {
    display: flex;
    font-family: "Nerko One", cursive;
    font-size: 2.1rem;
}

.made-by {
    text-decoration: none;
    position: relative;
    display: flex;
    align-items: center;
    margin: 2vh 0 2vh 0;
    padding: 3px 5px 5px 5px;
    border: 1px solid #42151b;
    border-radius: 10px;
    font-family: "SUSE", sans-serif;
    color: #42151b;
    opacity: 0.4;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
    -webkit-touch-callout: none;
}

.buttons {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: 12vh;
    margin-top: 4vh;
}

.buttons .arrows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    min-height: 15vh;
    aspect-ratio: 1/1;
}

.buttons .horizontal {
    display: flex;
    gap: 8vw;
}

.buttons .circle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 27vw;
    height: 10vh;
}

.buttons .circle>:first-child {
    align-self: flex-end;
}

.buttons .circle>:last-child {
    align-self: flex-start;
}

.auxilary-buttons {
    position: relative;
    display: flex;
    gap: 2rem;
    margin-top: 10vh;
}

@keyframes pixelReveal {
    0% { clip-path: inset(0 100% 0 0); }
    100% { clip-path: inset(0 0 0 0); }
}

@keyframes simpleFadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes pixelBlink {
    0%, 20% { opacity: 0; }
    21%, 100% { opacity: 1; }
}

.welcome-title, .welcome-subtitle, .welcome-press-button {
    font-family: 'Press Start 2P', monospace;
    image-rendering: pixelated;
}

.welcome-title {
    animation: pixelReveal 0.8s steps(8, end) forwards;
    white-space: nowrap;
    overflow: hidden;
}

.welcome-subtitle {
    animation: simpleFadeIn 0.8s ease-out 1.2s both;
}

.welcome-press-button {
    animation: 
      simpleFadeIn 0.3s ease-out 2.5s both,
      pixelBlink 2s infinite 3.3s;
}

@media screen and (min-width: 660px) {
    .base-container-landscape {
        position: fixed;
        z-index: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        min-height: 100%;
        min-width: 100%;
        overflow: hidden;
        background-color: rgb(138, 138, 180);
    }

    .left-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 25vw;
    }

    .arrows-landscape {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 18vh;
        padding-left: 2vw;
    }

    .arrows-landscape .horizontal {
        display: flex;
        flex-direction: row;
        gap: 9vh;
    }

    .exit-landscape {
        justify-content: flex-end;
        padding-left: 2vw;
    }

    .center-section {
        justify-self: center;
        align-items: center;
        align-self: center;
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 50vw;
    }

    .screen-container-landscape {
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 85vh;
        border-radius: 8px;
        background-color: black;
    }

    .screen-container-landscape .screen {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 75%;
        width: 85%;
        margin-top: 2vh;
        aspect-ratio: 1/1;
        border: 1px solid #4a4e44; 
        font-family: "Press Start 2P", system-ui;
        color: black;
        text-align: center;
        background-color: #5d6154;
        box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.5);
    }
    
    .screen-container-landscape .screen p {
        padding: 0.6rem 0 0.8rem 0;
        font-size: 0.7rem;
    }
    
    .screen-container-landscape .screen> :last-child {
        width: 100%;
        font-size: 0.7rem;
        flex-wrap: wrap;
    }
    
    .screen-container-landscape .screen .menu-options {
        display: flex;
        flex-direction: column;
        padding-top: 2vh;
        gap: 10px;
        list-style-type: none;
    }
    
    .screen-container-landscape .logo {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 9%;
        padding-top: 0.9rem;
        margin-bottom: 0.5vh;
        gap: 1vw;
        -webkit-tap-highlight-color: transparent;
        user-select: none;
        touch-action: manipulation;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-drag: none;
        user-drag: none;
        -webkit-touch-callout: none;
    }
    
    .screen-container-landscape .logo .main {
        font-family: "Cabin", sans-serif;
        font-weight: 800;
        font-style: italic;
        font-size: 2.1rem;
        color: #939393;
    }
    
    .screen-container-landscape .logo .advance {
        display: flex;
        font-family: "Orbitron", sans-serif;
        font-size: 1.5rem;
        font-weight: 900;
        color: rgb(209, 209, 209);
    }

    .made-by-landscape {
        text-decoration: none;
        display: flex;
        text-align: center;
        align-items: center;
        width: auto;
        margin: 3vh 2vw 4vh 0;
        padding: 3px 5px 5px 5px;
        border: 1px solid #42151b;
        border-radius: 10px;
        font-family: "SUSE", sans-serif;
        font-size: 0.8rem;
        color: #42151b;
        opacity: 0.4;
        -webkit-tap-highlight-color: transparent;
        user-select: none;
        touch-action: manipulation;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-drag: none;
        user-drag: none;
        -webkit-touch-callout: none;
    }

    .right-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 25vw;
    }

    .circle-buttons-landscape {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 20vh;
        align-items: center;
        justify-content: center;
        gap: 3vw;
        padding-bottom: 30vh;
    }

    .circle-buttons-landscape>:first-child {
        align-self: flex-end;
    }
}